<template>
  <div class="login-page">
    <img src="../img/loading.gif" class="loading-gif" alt="Loading..." />
  </div>
</template>

<script>
import api from '@/service/api'
import http from '@/service/axios'
import { idaasURL } from '@/config/config'
import { Modal } from 'ant-design-vue'
import { setCookie, deleteCookie } from '@/common/cookie'
import { mapActions, mapMutations } from 'vuex'
import { singleSignOnURL } from '@/config/config'

export default {
  mounted() {
    const query = this.$route.query
    if (query.hmac && (query.session === 'null' || !query.session)) {
      //shpify平台登录oms跳转
      return this.goToShopifyAuth(query)
    }
    if (query.sessionId) {
      //PDD 平台授权跳转过来
      this.userInfo.sessionId = query.sessionId
      this._page = '/shop_web'
      this.login(0)
      return
    }

    if (query.code) {
      //单点登录回调
      localStorage.setItem('apiURL', query.gateway_uri)
      setTimeout(() => {
        this.oauthLogin(query.code)
      })
      return
    } else if (query.switchAppCode) {
      this.switchApp(query.switchAppCode)
    } else {
      window.location.href = singleSignOnURL
    }
  },
  data() {
    return {
      userInfo: {
        merchantName: '',
        userName: '',
        password: '',
        validDays: 7,
        _page: '',
      },
      errorMsg: '',
      authCode: '',
      userList: [],
      loginType: 'pwd', //pwd账号密码 ding钉钉扫码 dingList扫码回来选择商户列表
      dingGoto: '',
      activeUser: {},
      loginLoading: false,
      show: false,
      cpwTips: '',
      menus: [],
      tipVisible: false,
      resData: {
        day: '',
        partyList: [],
      },
      registVisible: false,
      modalData: {},
      confirmLoading: false,
      showPercent: false,
      percent: 0,
      timer: null,
      isRegister: false,
      buList: [],
      merchantCode: undefined,
      resetEmail: undefined,
      forgetPwdVisible: false,
    }
  },
  computed: {
    modalBind() {
      let data = {
        maskClosable: false,
      }
      if (this.showPercent) {
        data.footer = null
        data.closable = false
        data.keyboard = false
      }
      return data
    },
    isCN() {
      return window.location.href.startsWith('https://oms.geezdata.com')
    },
  },
  methods: {
    ...mapMutations(['setState']),
    ...mapActions(['getShopInfoList']),
    menusJump(query) {
      let view = ''
      if (this.menus.length == 0) {
        this.menus = this.$store.state.menuList
      }
      let pageCacheFullPath = localStorage.getItem('pageCacheFullPath')
      if (pageCacheFullPath) {
        view = pageCacheFullPath
        localStorage.removeItem('pageCacheFullPath')
        this.$router.push(view)
        this.loginLoading = false
        return
      }
      let orderMenu = this.menus.filter((item) => item.view === 'orderManage')
      console.log('orderMenu: ', orderMenu)
      let distributionMenus = this.menus.filter((item) => {
        return item.view === 'distributionManagement' || item.view === 'distributionPlatform'
      })
      if (orderMenu.length) {
        view = 'orderManage'
        this.$router.push({ name: 'orderManage', query })
      } else if (distributionMenus.length) {
        if (distributionMenus.length === 2) {
          view = 'distributionManagement'
        } else if (distributionMenus[0].view === 'distributionManagement') {
          view = 'distributionManagement'
        } else if (distributionMenus[0].view === 'distributionPlatform') {
          view = 'distributionPlatform'
        }
        query ? this.$router.push({ name: view, query }) : this.$router.push({ name: view })
      } else {
        view = this.menus[0].view
        this.$router.push('/' + view)
      }
      this.loginLoading = false
    },
    login(needMd5 = true) {
      let isTrue = true
      let nowPassword = ''
      let data = {}
      if (this.userInfo.sessionId) {
        data.sessionId = this.userInfo.sessionId
      } else {
        if (!this.userInfo.merchantName) {
          return (this.errorMsg = this.$t('请输入商家名称'))
        }
        deleteCookie()
        // localStorage.removeItem("currentShop");
        this.errorMsg = ''
      }
      for (var p in this.userInfo) {
        data[p] = this.userInfo[p]
      }
      if (needMd5) {
        var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
        isTrue = reg.test(data.password)
        nowPassword = data.password
        const shaObj = new this.$jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' })
        shaObj.update(data.password)
        data.password = shaObj.getHash('HEX')
      }
      this.loginLoading = true
      localStorage.removeItem('originalMerchantCode')
      http({
        url: api.login,
        type: 'post',
        data,
        success: (res) => {
          if (isTrue || this.isRegister) {
            let userInfo = res.result.user
            this.setUserInfo(userInfo)
          } else {
            this.merchantCode = res.result.user.merchantCode
            this.cpwTips =
              this.$t('当前密码过于简单，请修改密码') + '(' + this.$t('初始密码为手机号') + ')'
            this.$refs.changePassword.hasLogin = true
            this.$refs.changePassword.visiable = true
            this.loginLoading = false
          }
        },
        fail: (res) => {
          data.password = nowPassword
          this.errorMsg = res.subMsg || res.msg
          this.loginLoading = false
        },
      })
    },
    async getShopList() {
      let res = await http({
        url: api.getNewShopInfoList,
        data: {
          status: 1,
          merchantCode: localStorage.getItem('merchantCode'),
        },
      })
      let list = res.result.list || []
      this.setParty(list[0])
      if (list.length === 0) {
        this._page = '/shop_web'
      } else {
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].authStatus === 'AUTH_EXPIRED' ||
            (list[i].authStatus === 'AUTHED' && list[i].authExpiredDays < 5)
          ) {
            setTimeout(() => {
              Modal.confirm({
                title: list[i].shopName + this.$t(`店铺授权快失效了，快去重新授权吧`),
                // content: this.$t(`重新授权`),
                okText: this.$t(`重新授权`),
                cancelText: this.$t(`取消`),
                onOk: () => {
                  localStorage.removeItem('partyList')
                  this.$router.push('/shop_web/shopManage?shopName=' + list[i].shopName)
                },
              })
            }, 1500)
            break
          }
        }
      }
    },
    //判断是不是新的订单服务
    async queryIdaasAppList(merchantCode) {
      try {
        let res = await http({
          url: api.queryIdaasAppList,
          data: {
            merchantCode,
          },
        })
        let list = res.result,
          obj = {}
        list.forEach((item) => {
          obj[item.appCode] = item
        })
        localStorage.setItem('currentIdaasApp', JSON.stringify(obj))
        Common.currentIdaasApp = obj
        return Promise.resolve()
      } catch (err) {
        return Promise.resolve()
      }
    },
    setParty(party) {
      party = party || this.buList[0]
      if (!party) {
        this.$message.warning(this.$t('业务组为空'))
        this.goJump()
        return
      }
      this.setState({
        currentShop: party,
      })
      http({
        url: api.switchParty,
        type: 'post',
        data: { buId: party.departmentId || party.id },
        success: async (res) => {
          const userSession = res.result
          party = userSession.currentBu || party
          party.partyId = party.id
          party.partyName = party.name
          userSession.merchant = {
            merchantCode: userSession.merchantCode,
          }
          userSession.user = {
            name: userSession.userName,
            id: userSession.userCode,
          }
          userSession.currentParty = party //接口换了 只好前端来兼容
          this.setState({
            userSession,
          })
          await this.queryIdaasAppList(userSession.merchantCode)
          this.goJump()
        },
      })
    },
    goJump() {
      if (this._page) {
        this.$router.push(this._page)
      } else {
        let query = {}
        this.menusJump(query)
      }
    },
    goToShopifyAuth(query) {
      const client_id = '07994eae816de6af0084ec7cff34acf1',
        scope =
          'read_inventory,write_inventory,read_orders,write_orders,read_products,write_products,read_fulfillments,write_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders'
      let url = `https://${query.shop}/admin/oauth/authorize?client_id=${client_id}&scope=${scope}&redirect_uri=${idaasURL}/oauth&state=fromOMS`
      location.href = url
    },
    oauthLogin(code) {
      http({
        url: api.authorizeOauth,
        type: 'post',
        data: {
          authCode: code,
        },
        success: (res) => {
          setCookie({ d_session_id: res.result })
          this.getSession(res.result)
        },
        fail: (res) => {
          if (res.message === 'authCode is invalid!') {
            window.location.href = singleSignOnURL
          }
        },
      })
    },
    getSession() {
      http({
        url: api.getSession,
        type: 'post',
        data: {
          appCode: 'OMS',
        },
        success: (res) => {
          this.setUserInfo(res.result)
        },
      })
    },
    setUserInfo(userInfo) {
      setCookie({ reportPlatform: 'oms' })
      setCookie({ scmPlatform: 'SCM' })
      this.menus = userInfo.menuList
      this.setState({
        menuList: userInfo.menuList,
      })
      this.buList = userInfo.buList
      this.setState({
        userInfo: userInfo,
      })
      let merchantCode = userInfo.merchantCode
      this.merchantCode = merchantCode

      localStorage.setItem('userAccount', userInfo.currentUser)
      localStorage.setItem('merchantName', userInfo.merchantName)
      localStorage.setItem('merchantCode', merchantCode)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      localStorage.setItem('originalMerchantCode', userInfo.originalMerchantCode)
      this.errorMsg = ''
      this.getShopList()
    },
    switchApp(switchAppCode) {
      http({
        url: api.switchApp,
        type: 'post',
        data: {
          switchAppCode,
        },
        success: (res) => {
          this.setUserInfo(res.result)
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login-page {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .loading-gif {
    width: 100px;
  }
}
</style>
